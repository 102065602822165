<template>
    <div>
        <div v-if="contracts" class="data-table__wrapper">
            <table class="data-table__table">
                <thead class="data-table__headings">
                <th class="data-table__heading"
                >№
                </th>
                <th class="data-table__heading" v-for="(header, idx) in headers"
                    :key="'header-'+header.value + '_'+ idx"
                >{{ header.name }}
                    <span v-if="!header.link"
                          class="inline-block mx-1"
                    >
                    </span>
                </th>
                <th></th>
                <th></th>


                </thead>
                <tbody class="data-table__body">
                <tr class="data-table__row"
                    v-for="(data, idx) in contracts"
                    :key="'item-'+data.id"

                >
                    <td class="p-3">{{ idx + 1 }}</td>
                    <td @dblclick="$router.push({name:'ContractDetail', params: {id:data.id}})"
                        class="p-3 "
                        :key="header.value + '_'+ idx"
                        v-for="(header, idx) in headers"
                    >
                        <span>{{ data[header.value] || "—" }}</span>
                    </td>
                    <td class="bg-green-500 text-white text-center" @click="resolve(data.id, 'confirm')">
                        Принять
                    </td>
                    <td class="bg-red-600 text-white text-center" @click="resolve(data.id, 'reject')">
                       Отклонить
                    </td>

                </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <h3 class="text-2xl text-center w-full p-10">
                Список компаний пуст пуст
                <g-emoji>😥</g-emoji>
            </h3>
        </div>
        <ResolveRequestModal :id="id" :show-modal.sync="showModal" :type="type"></ResolveRequestModal>
        <Pagination dashboard :hideNext="hideNext" namespace="admin"></Pagination>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Pagination from '@/components/common/Pagination'
import ResolveRequestModal from './ContractsRequestsModal'
export default {
    name: "ContractsAll",
    components:{Pagination, ResolveRequestModal},
    data:()=> ({
        showModal:false,
        id:null,
        type:'',
        headers: [
            {name: 'Пользователь', value: 'login'},
            {name: 'Телефон пользователя', value: 'uf_contact_phone'},
            {name: 'Наименование юр.лица', value: 'uf_name'},
            {name: 'УНП', value: 'uf_unp'},
            {name: 'Страна юр.лица', value: 'uf_org_country'},
        ]
    }),
    created() {
        this.$store.dispatch('admin/getContractsRequests')
    },
    computed: {
        ...mapGetters({
            contracts: 'admin/requests',
            perPage: 'admin/perPage'
        }),
        hideNext() {
            return this.contracts.length === 0 || this.contracts.length < this.perPage;
        }
    },
    methods:{
        resolve(id,type){
            this.type = type
            this.id = id
            this.showModal = true
        }
    }
}
</script>

<style scoped>
::-webkit-scrollbar {
    display: none;
}
.data-table__wrapper {
    @apply overflow-y-scroll max-h-128  shadow-md
}

.dark .data-table__wrapper {
    box-shadow: none;
}

.data-table__table {
    @apply w-full text-left table dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm
}

.data-table__headings {
    @apply dark:bg-gray-800 bg-white text-gray-500
}

.data-table__heading {
    @apply p-3 select-none
}

.data-table__body {
    @apply dark:text-gray-400 text-gray-600
}

.data-table__row {
    @apply dark:bg-gray-800 bg-white cursor-pointer dark:hover:bg-gray-700 hover:bg-gray-300
}

::-webkit-scrollbar {
    display: block;
    overflow: auto;
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: lightskyblue;
}
</style>